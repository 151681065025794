.accordion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  max-width: 500px;
  border-bottom: 1px solid #fdff00;
}

.accordion-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  max-width: 500px;
  border-bottom: 1px solid #fdff00;
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms ease;
}

.accordion-list {
  align-content: left;
  padding-right: 15px;
}

.accordion-content.open {
  max-height: 700px;
}

.accordion-content li {
  text-align: left;
  list-style-type: none;
  padding-bottom: 10px;
}

.project-cta {
  text-decoration: underline;
  margin: 5px auto;
  height: 30px;
  max-width: 400px;
  min-width: 200px;
  color: #fdff00;
  border-radius: 3px;
  transition: color .3s;
}

.project-cta:hover {
  cursor: pointer;
  color: white;
}

.fa-plus {
  transition: transform .3s, color .3s;
  margin-top: 25px;
}

.fa-plus:hover {
  cursor: pointer;
}

#open {
  transform: rotate(-45deg);
}