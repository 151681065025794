.homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #00ff03;
  min-height: 100vh;
}

@media (max-width: 520px) {
  .homepage {
    min-height: 90vh;
    width: 100vw;
  }
}