.header-home-mobile {
  width: 100vw;
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
}

.header-home-mobile i {
  color: #00ff03;
  margin: 10px;
  font-size: 20px;
}

.header-about-mobile {
  width: 100vw;
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
}

.header-about-mobile i {
  color: #ff0002;
  margin: 10px;
  font-size: 20px;
}

.header-project-mobile {
  width: 100vw;
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
}

.header-project-mobile i {
  color: #fdff00;
  margin: 10px;
  font-size: 20px;
}

.header-contact-mobile {
  width: 100vw;
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
}

.header-contact-mobile i {
  color: #01fffe;
  margin: 10px;
  font-size: 20px;
}

.burger-container {
  max-height: 0px;
}

.nav-transition-enter {
  opacity: 0.01;
}

.nav-transition-enter.nav-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms ease;
}

.nav-transition-exit {
  opacity: 1;
}

.nav-transition-exit.nav-transition-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease;
}