.contact {
  color: #01fffe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
}

@media (max-width: 520px) {
  .contact {
    height: 70vh;
    width: 100vw;
  }
}