@font-face {font-family: MONO; src: url('./customFonts/mononokekeh/mononokekeh.ttf');}
@font-face {font-family: xevious; src: url('./customFonts/xevious/xevious.ttf');}

.App {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  font-weight: normal;
}

h1 {
  font-size: calc(40px + (50 - 40) * ((100vw - 300px) / (1600 - 300)));
  font-family: xevious;
  font-weight: normal;
}

.character-container {
  font-family: MONO;
  font-size: 100px;
  font-weight: normal;
  margin: 50px;
}

@media (max-width: 420px) {
  h1 {
    margin: 10px 0px;
  }

  p {
    font-size: 18px;
  }

  h3 {
    font-size: 18px;
  }

  .character-container {
    font-family: MONO;
    font-size: 70px;
    font-weight: normal;
    margin: 10px 0px;
  }
}