.projects {
  color: #fdff00;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

@media (max-width: 520px) {
  .projects {
    min-height: 90vh;
    width: 100vw;
  }
  .projects-content {
    margin: 0px 25px;
  }
}