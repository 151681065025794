.footer {
  background-color: black;
  margin-bottom: 20px;
  color: #01fffe;
}

.footer a {
  text-decoration: none;
  font-size: 35px;
  margin: 20px;
  border: 0px solid black;
  color: #01fffe;
  transition: color .3s;
}

.footer a:hover {
  cursor: pointer;
  color: white;
}

@media (max-width: 520px) {
  .footer {
    margin-bottom: 50px;
    margin-top: 50px;
    width: 100vw;
  }
}