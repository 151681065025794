.about {
  color: #ff0002;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 105vh;
}

.about-headline {
  max-width: 575px;
  margin: auto;
}

.about a {
  color: #ff0002;
  font-size: 20px;
  max-width: 300px;
  margin: 0px auto;
}

.about a:hover {
  color: white;
}

@media (max-width: 520px) {
  .about {
    min-height: 95vh;
    width: 100vw;
  }

  .about-headline {
    margin: 10px;
  }
}