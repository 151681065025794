.close-button-container {
  display: flex;
  flex-direction: row-reverse;
}

.nav-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 80vh;
  margin-bottom: 20px;
}

.open-burger-home {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 5;
}

.open-burger-home a {
  text-decoration: none;
  color: #00ff03;
  font-size: 30px;
}

.open-burger-home .fa-close {
  margin: 10px;
  font-size: 25px;
  color: #00ff03;
}

.open-burger-about {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 5;
}

.open-burger-about a {
  text-decoration: none;
  color: #ff0002;
  font-size: 30px;
}

.open-burger-about .fa-close {
  margin: 10px;
  font-size: 25px;
  color: #ff0002;
}

.open-burger-projects {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 5;
}

.open-burger-projects a {
  text-decoration: none;
  color: #fdff00;
  font-size: 30px;
}

.open-burger-projects .fa-close {
  margin: 10px;
  font-size: 25px;
  color: #fdff00;
}

.open-burger-contact {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 5;
}

.open-burger-contact a {
  text-decoration: none;
  color: #01fffe;
  font-size: 30px;
}

.open-burger-contact .fa-close {
  margin: 10px;
  font-size: 25px;
  color: #01fffe;
}